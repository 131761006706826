// https://docs.piral.io/reference/documentation/loading-strategies

import { PiletMetadata } from 'piral'
import { LoadPiletsOptions, PiletsLoaded, standardStrategy } from 'piral-base'

const pbaUpdatePiletName = 'pba-update-ui'

const pbaUpdatePiletFirst = (a, b) => {
  return a.name === pbaUpdatePiletName ? -1 : b.name === pbaUpdatePiletName ? 1 : 0
}

const feedUrl = (window as any)._env_.FEED_URL

export function piletRequester(): Promise<Array<PiletMetadata>> {
  if (window.location.port === '7999') {
    console.warn('piral debug mode - no pilets')
    return new Promise((resolve) => {
      resolve([])
    })
  }

  return fetch(feedUrl)
    .then((res) => res.json())
    .then((res) => {
      console.debug('pilets received from feed:', res.items)
      const items = res.items?.sort(pbaUpdatePiletFirst)
      console.debug('pilets received from feed:', items)
      return items
    })
    .catch((reason) => {
      console.error('Error fetching pilets from url', feedUrl, reason)
      return []
    })
}

export function pbaUpdateFirstLoadingStrategy(options: LoadPiletsOptions, cb: PiletsLoaded): PromiseLike<void> {
  const updatedOptions = { ...options, pilets: (options.pilets || []).sort(pbaUpdatePiletFirst) }
  console.debug('Loading strategy, options:', updatedOptions)
  return standardStrategy(updatedOptions, cb)
}
