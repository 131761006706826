import React from 'react'
import { Space } from 'antd'
import { HelpLink } from './HelpLink'
import { SystemMessage } from './SystemMessage'
import { OidcClient } from 'piral-oidc'
import { Menu } from 'piral'

export const HeaderActions: React.FC<{ oidcClient: OidcClient }> = ({ oidcClient }) => (
  <Space direction='horizontal' size='large'>
    <Menu type={'general'} />
    <SystemMessage oidcClient={oidcClient} />
    <HelpLink />
  </Space>
)
