window._env_ = {
  FEED_URL: 'http://feedservice/api/v1/pilet/pba',
  OIDC_URL: __OIDC_URL__,
  OIDC_CLIENT: 'pba-frontend',
  SENTRY_DSN: 'https://e1780a5eae884f31b9cebca2d2903cb7@sentry.wrwks.at/44',
  SENTRY_RELEASE: '0.0.0',
  SENTRY_ENVIRONMENT: 'dev',
  SENTRY_ENABLED: 'false',
  MATOMO_URL: 'https://stage-piwik.wrwks.at',
  MATOMO_SITE_ID: '0',
  MATOMO_ENABLED: 'false'
}
