import Axios, { AxiosInstance, CancelTokenSource } from 'axios'
import { PiralPlugin } from 'piral'
import oidcClient from '../security/OidcClient'

export interface FetchApi {
  axiosInstance(): AxiosInstance
  createCancelTokenSource(): CancelTokenSource
}

export function createPbaFetchApi(): PiralPlugin<FetchApi> {
  const instance = Axios.create({
    baseURL: '/',
    headers: {
      'Content-Type': 'application/json'
    }
  })

  instance.interceptors.request.use(
    async (request) => {
      try {
        const token = await oidcClient.token()
        request.headers.Authorization = `Bearer ${token}`
      } catch (oidcError) {
        console.error('unable to retrieve token, trigger re-authentication', oidcError)
        try {
          await oidcClient.login()
        } catch (e) {
          console.error('unable to redirect to login page, use hard reload', e)
          window.location.reload()
        }
      }
      return request
    },
    (error) => Promise.reject(error)
  )

  return () => ({
    axiosInstance: () => instance,
    createCancelTokenSource: () => Axios.CancelToken.source()
  })
}
