import React from 'react'
import { Layout, Space } from 'antd'

import styles from './PbaAppLayout.module.css'
import { Logo, LogoType } from 'bit-ui_component_lib-b2b'

const { Content, Header } = Layout

interface AppLayoutProps {
  logoType: LogoType
  appNameLong: string
  appNameShort: string
  appNameSub: string
  headerActions?: React.ReactNode
  userMenu: React.ReactNode
  content: React.ReactNode
}

export const PbaAppLayout: React.FC<AppLayoutProps> = (props: AppLayoutProps) => {
  return (
    <Layout className={styles.layout}>
      <Header className={styles.header}>
        <Logo className={styles.logo} type={props.logoType} />
        <div className={`${styles.application} ${styles.long}`}>
          <div className={styles.title}>{props.appNameLong}</div>
          <div className={styles.sub}>{props.appNameSub}</div>
        </div>
        <div className={`${styles.application} ${styles.short}`}>{props.appNameShort}</div>
        <Space direction='horizontal' className={styles.actions}>
          <div className={styles.headerActions}>{props.headerActions}</div>
          {props.userMenu}
        </Space>
      </Header>
      <Layout className={styles.mainContent}>
        <Content className={styles.content}>{props.content}</Content>
      </Layout>
    </Layout>
  )
}
