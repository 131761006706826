import * as React from 'react'
import { PropsWithChildren, useEffect, useState } from 'react'
import { Result } from 'antd'
import { ErrorInfoProps } from 'piral'

export const UnexpectedErrorAlert = () => (
  <Result
    status='error'
    title='Unerwarteter Fehler'
    subTitle='Es ist ein unerwarteter Fehler aufgetreten. Bitte aktualisieren Sie die aktuelle Seite. Sollte das Problem dadurch nicht behoben werden, kontaktieren Sie bitte den Support.'
  />
)

interface Props {
  errorInfo: PropsWithChildren<ErrorInfoProps>
}

const NotFound = () => {
  return (
    <Result
      status='warning'
      title='Seite nicht gefunden'
      subTitle='Die gewünschte Seite konnte nicht gefunden werden. Bitte Klicken Sie auf das "Stadt Wien - Wiener Wohnen" Logo links oben, um auf die Startseite zurückzukehren.'
    />
  )
}

const OidcError = () => {
  const [redirect, setRedirect] = useState<boolean>(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setRedirect(true)
    }, 5000)
    return () => clearTimeout(timer)
  }, [])

  if (redirect) {
    if (document.location.href !== '/') {
      document.location.href = '/'
    }
  }

  return (
    <Result
      status='warning'
      title='Session abgelaufen'
      subTitle='Ihre Session ist abgelaufen. Sie werden nach wenigen Sekunden automatisch zur Login-Seite weitergeleitet. Sollte die Weiterleitung nicht funktionieren aktualisieren Sie bitte die Seite in Ihrem Browser.'
    />
  )
}

export const ErrorBoundary = ({ errorInfo }: Props) => {
  if (errorInfo.type === 'not_found') {
    return <NotFound />
  } else {
    const errorObject = (errorInfo as any).error
    if (errorObject.name && errorObject.name === 'OidcError') {
      console.error('Got the following OidcError, refreshing the page after 5 seconds...')
      console.error(errorObject)
      return <OidcError />
    }
  }

  console.error('The following error occurred in ErrorBoundary:')
  console.error(errorInfo)
  return <UnexpectedErrorAlert />
}
