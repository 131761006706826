import React, { ReactNode } from 'react'
import styles from './AppContent.module.css'

import { LogoType } from 'bit-ui_component_lib-b2b'
import { PbaUserMenu } from './usermenu/PbaUserMenu'
import { OidcClient } from 'piral-oidc'
import { HeaderActions } from './layout/HeaderActions'
import { PbaAppLayout } from './PbaAppLayout'

interface AppContentProps {
  children: ReactNode
  oidcClient: OidcClient
}

export const AppContent = ({ children, oidcClient }: AppContentProps) => (
  <PbaAppLayout
    logoType={LogoType.WRW}
    appNameLong='Plan- und Bescheidarchiv'
    appNameShort='PBA'
    appNameSub='Fachbereich Facility Management – Dezernat Immobilienportfoliomanagement'
    headerActions={<HeaderActions oidcClient={oidcClient} />}
    userMenu={<PbaUserMenu oidcClient={oidcClient} />}
    content={
      <div id='pba-content' className={`${styles.background} container`}>
        {children}
      </div>
    }
  />
)
