import React, { useEffect, useState } from 'react'
import { notification, Tooltip } from 'antd'
import { BellTwoTone } from '@ant-design/icons'
import { OidcClient } from 'piral-oidc'
import jwtDecode from 'jwt-decode'

export const SystemMessage = ({ oidcClient }: { oidcClient: OidcClient }) => {
  const [systemMessage, setSystemMessage] = useState<string>(null)
  const openNotification = (message: String) => {
    notification['warning']({
      icon: <BellTwoTone twoToneColor='#FF0102' />,
      message: 'System Nachricht',
      description: message
    })
  }

  useEffect(() => {
    const updateSystemMessage = async () => {
      const token = await oidcClient.token()
      const jwt = jwtDecode<any>(token)

      await oidcClient.account()
      setSystemMessage(jwt.system_message || null)
    }

    updateSystemMessage()
  }, [oidcClient])

  return (
    <>
      {!!systemMessage && systemMessage.length ? (
        <Tooltip title='System Nachricht'>
          <div
            style={{
              color: '#343434',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              marginRight: '15px'
            }}
          >
            <BellTwoTone
              twoToneColor='#FF0102'
              style={{ fontSize: '20px' }}
              onClick={() => openNotification(systemMessage)}
            />
          </div>
        </Tooltip>
      ) : (
        <></>
      )}
    </>
  )
}
