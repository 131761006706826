import React from 'react'
import { Tooltip } from 'antd'
import { MailOutlined } from '@ant-design/icons'

export const HelpLink = () => {
  return (
    <Tooltip title='Kontakt aufnehmen'>
      <a href='mailto:it@wrwks.at?subject=PBA' target='_blank' rel='noreferrer'>
        <MailOutlined
          style={{
            color: 'var(--wrw-text-color)',
            fontSize: '20px',
            verticalAlign: 'middle'
          }}
        />
      </a>
    </Tooltip>
  )
}
