import { PiralPlugin } from 'piral'
import { createInstance } from '@jonkoops/matomo-tracker-react'
import MatomoTracker from '@jonkoops/matomo-tracker'

const matomoUrl: string = (window as any)._env_.MATOMO_URL
const matomoInstance = createInstance({
  urlBase: matomoUrl + '/',
  siteId: (window as any)._env_.MATOMO_SITE_ID,
  trackerUrl: matomoUrl + '/matomo.php',
  disabled: (window as any)._env_.MATOMO_ENABLED?.toLowerCase()?.trim() !== 'true',
  linkTracking: false
})

export interface TrackingApi {
  matomoInstance(): MatomoTracker
}

export function createPbaTrackingApi(): PiralPlugin<TrackingApi> {
  return () => ({
    matomoInstance: () => matomoInstance
  })
}
