import React from 'react'
import { Button, Dropdown } from 'antd'
import { useGlobalState } from 'piral'
import { MenuOutlined } from '@ant-design/icons'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import { useHistory } from 'react-router'

const WrwMenu = () => {
  const history = useHistory()
  const menuItems = useGlobalState((m) => m.registry.menuItems)
  const menuItemKeys = Object.keys(menuItems)
  const sortedMenuItems = menuItemKeys
    .map((key) => menuItems[key])
    .filter((mi) => mi.settings.type === 'general')
    .sort((a, b) => a.settings.order - b.settings.order)

  if (!sortedMenuItems || sortedMenuItems.length < 2) {
    return null
  }

  const items: ItemType[] = sortedMenuItems.map((mi) => ({
    label: mi.settings.title,
    key: mi.settings.key,
    icon: mi.settings.icon,
    onClick: () => (mi.settings.external ? window.open(mi.settings.link, '_blank') : history.push(mi.settings.link))
  }))

  return (
    <Dropdown menu={{ items }} placement={'bottomLeft'} trigger={['click']}>
      <Button
        icon={<MenuOutlined style={{ color: 'var(--wrw-text-color)', fontSize: '20px' }} />}
        style={{ verticalAlign: 'middle' }}
        type='default'
      />
    </Dropdown>
  )
}

export default WrwMenu
