import { LogLevel, setupOidcClient } from 'piral-oidc'

const mock = () => ({
  _: {},
  login: async () => {},
  logout: async () => {},
  revoke: async () => {},
  handleAuthentication: async () => {
    return {
      shouldRender: true,
      state: 'OK'
    }
  },
  account: async () => {
    return {
      iss: 'iss',
      sub: 'sub',
      aud: 'aud',
      exp: 100000,
      iat: 1000000,
      name: 'Alois Mock',
      given_name: 'Alois',
      family_name: 'Mock'
    }
  },
  token: async () => {
    return 'MOCK-TOKEN'
  },
  extendHeaders: (req) => {}
})

const createOidcClient = () => {
  if (window.location.port === '7999') {
    return mock()
  }

  const idpHint = new URLSearchParams(window.location.search).get('idpHint') ?? 'adfs'

  return setupOidcClient({
    clientId: (window as any)._env_.OIDC_CLIENT,
    identityProviderUri: (window as any)._env_.OIDC_URL,
    responseType: 'code',
    extraQueryParams: {
      kc_idp_hint: idpHint
    },
    signInRedirectParams: {
      state: {
        finalRedirectUri: window.location.href
      }
    },
    logLevel: LogLevel.error
  })
}

const oidcClient = createOidcClient()
export default oidcClient
