import * as React from 'react'
import { ComponentsState, ErrorComponentsState } from 'piral'
import { Link, Redirect } from 'react-router-dom'
import WrwMenu from './layout/WrwMenu'
import { getAppInstance } from 'piral/esm/app'
import { AppContent } from './AppContent'
import { createOidcApi } from 'piral-oidc'
import oidcClient from './security/OidcClient'
import { LoadingIndicator, LoadingIndicatorType } from 'bit-ui_component_lib-b2b'
import { ErrorBoundary } from './error/ErrorBoundary'
import { AuthenticationApi, createPbaAuthenticationApi } from './security/AuthenticationApi'
import { createPbaFetchApi, FetchApi } from './fetch/FetchApi'
import { createPbaQueryApi, QueryApi } from './query/QueryApi'
import { PiralConfiguration } from 'piral-core'
import { pbaUpdateFirstLoadingStrategy, piletRequester } from './pilet-loading'
import { createPbaTrackingApi, TrackingApi } from './tracking/TrackingApi'

declare module 'piral-menu/lib/types' {
  interface PiralCustomMenuSettings {
    key: string
    link: string
    title: string
    order?: number
    icon?: React.ReactNode
    external?: boolean
  }
}

declare module 'piral-core/lib/types/custom' {
  interface PiletCustomApi extends AuthenticationApi, FetchApi, QueryApi, TrackingApi {}
}

const errors: Partial<ErrorComponentsState> = {
  not_found: () => (
    <div>
      <p className='error'>Die gewünschte Seite konnte leider nicht gefunden werden.</p>
      <p>
        Zurück zur <Link to='/'>Startseite</Link>.
      </p>
    </div>
  )
}

const piralApplication: Partial<ComponentsState> = {
  ErrorInfo: (errorInfo) => <ErrorBoundary errorInfo={errorInfo} />,
  DashboardContainer: () => <Redirect to='/suche' />,
  DashboardTile: ({ columns, rows, children }) => <div className={`tile cols-${columns} rows-${rows}`}>{children}</div>, // eslint-disable-line  react/prop-types
  Layout: ({ children }) => <AppContent children={children} oidcClient={oidcClient} />, // eslint-disable-line react/prop-types,react/no-children-prop
  LoadingIndicator: () => <LoadingIndicator type={LoadingIndicatorType.APP} />,
  MenuContainer: WrwMenu
}

const config: PiralConfiguration = {
  requestPilets: piletRequester,
  async: pbaUpdateFirstLoadingStrategy,
  plugins: [
    createOidcApi(oidcClient),
    createPbaAuthenticationApi(),
    createPbaFetchApi(),
    createPbaQueryApi(),
    createPbaTrackingApi()
  ]
}

const { app, instance } = getAppInstance(config, { layout: piralApplication, errors })
export { app, instance, oidcClient }
