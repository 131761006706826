/**
 * index.tsx configure sentry & handlers
 */

import React from 'react'
import * as Sentry from '@sentry/react'
import { ReportDialogOptions } from '@sentry/browser'
import { Button, ConfigProvider, Result } from 'antd'
import App from './App'
import { setLocale } from 'yup'
import { yupLocaleDe } from './yupLocaleDe'
import deDE from 'antd/es/locale/de_DE'
import moment from 'moment'
// @ts-ignore
import deAT from 'moment/locale/de-at'

moment.updateLocale('de-at', deAT)
setLocale(yupLocaleDe)

Sentry.init({
  dsn: (window as any)._env_.SENTRY_DSN,
  release: (window as any)._env_.SENTRY_RELEASE,
  enabled: (window as any)._env_.SENTRY_ENABLED?.toLowerCase()?.trim() === 'true',
  environment: (window as any)._env_.SENTRY_ENVIRONMENT,
  ignoreErrors: [
    'ResizeObserver loop completed with undelivered notifications.',
    'ResizeObserver loop limit exceeded',
    'OidcError: Error: invalid_grant',
    'OidcError: Error: Network Error'
  ]
})

const fallbackComponent = () => {
  return (
    <Result
      status='error'
      title='Ein unbekanntes Problem ist aufgetreten'
      subTitle='Bitte laden Sie die Anwendung neu'
      extra={[
        <Button key='refresh' type='primary' onClick={() => (window.location.href = '/')}>
          Anwendung neu laden
        </Button>
      ]}
    />
  )
}

const sentryOptions: ReportDialogOptions = {
  lang: 'de',
  title: 'Ein unbekanntes Problem ist aufgetreten',
  successMessage: 'Danke! Ihr Feedback wurde erfolgreich gesendet'
}

const SentryWrapper: React.FC = () => {
  return (
    <ConfigProvider locale={deDE} prefixCls={'ant'}>
      <Sentry.ErrorBoundary fallback={fallbackComponent} showDialog dialogOptions={sentryOptions}>
        <App />
      </Sentry.ErrorBoundary>
    </ConfigProvider>
  )
}

export default SentryWrapper
