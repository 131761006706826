import React, { useState } from 'react'
import styles from './UserActions.module.css'
import { Button, Divider, Modal, Space } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'
import { OidcClient } from 'piral-oidc'

declare let window: any

const IFRAME_ID = 'account-page'

interface UserActionsProps {
  oidcClient: OidcClient
}

const UserActions = ({ oidcClient }: UserActionsProps) => {
  const [isBenutzerkontoVisible, setBenutzerkontoVisible] = useState(false)

  const handleCancel = () => {
    setBenutzerkontoVisible(false)
  }

  function getAccountUrl() {
    return window._env_.OIDC_URL + '/account/password?withHeader=false'
  }

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <Button
        danger
        className={styles.button}
        type='link'
        icon={<LogoutOutlined />}
        onClick={() => oidcClient.logout()}
      >
        Abmelden
      </Button>
      <Divider type='horizontal' className={styles.divider} />

      <div>
        <Modal
          title='Benutzerdaten ändern'
          open={isBenutzerkontoVisible}
          onCancel={handleCancel}
          footer={null}
          closable
          width={'70%'}
          bodyStyle={{ height: '80vh' }}
        >
          <iframe
            className={styles.iframe}
            id={IFRAME_ID}
            name={IFRAME_ID}
            title='Benutzerdaten ändern'
            src={getAccountUrl()}
          />
        </Modal>
      </div>
    </Space>
  )
}

export default UserActions
