import { QueryClient } from 'react-query'
import { PiralPlugin } from 'piral'

export const queryClient = new QueryClient()

export interface QueryApi {
  queryClient(): QueryClient
}

export function createPbaQueryApi(): PiralPlugin<QueryApi> {
  return () => ({
    queryClient: () => queryClient
  })
}
