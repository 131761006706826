import * as React from 'react'
import { useState } from 'react'
import 'piral-menu'
import { app, oidcClient } from './piral-application'
import { QueryClientProvider } from 'react-query'
import { queryClient } from './query/QueryApi'

const logoutOnError = async () => {
  try {
    await oidcClient.logout()
  } catch (error) {
    console.error(
      'Error while logging out. A page reload will be triggered so the browser should redirect to login page'
    )
    window.location.reload()
  }
}

const App: React.FC = () => {
  const [visible, setVisible] = useState(false)
  oidcClient
    .handleAuthentication()
    .then(async ({ shouldRender, state }) => {
      if (state?.finalRedirectUri) {
        window.location.href = state.finalRedirectUri
      } else {
        setVisible(shouldRender)
      }
    })
    .catch(async (err) => {
      console.error('The following authentication error occured:')
      console.error(err)
      await logoutOnError()
    })

  return visible && <QueryClientProvider client={queryClient}>{app}</QueryClientProvider>
}

export default App
