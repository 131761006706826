import { PiralPlugin } from 'piral'
import oidcClient from './OidcClient'
import jwtDecode from 'jwt-decode'

export interface AuthenticationApi {
  hasAnyRole(roles: string[]): Promise<boolean>

  getRoles(): Promise<string[]>
}

export function createPbaAuthenticationApi(): PiralPlugin<AuthenticationApi> {
  return () => ({
    async hasAnyRole(roles: string[]): Promise<boolean> {
      const token = await oidcClient.token()
      const jwt: any = jwtDecode(token)
      return new Promise((resolve) => resolve(jwt?.realm_access?.roles?.some((it) => roles.includes(it))))
    },

    async getRoles(): Promise<string[]> {
      const token = await oidcClient.token()
      const jwt: any = jwtDecode(token)
      return new Promise((resolve) => resolve(jwt?.realm_access?.roles))
    }
  })
}
