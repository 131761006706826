import React, { useEffect, useState } from 'react'
import { LoadingIndicator, LoadingIndicatorType, UserMenu } from 'bit-ui_component_lib-b2b'
import UserActions from './UserActions'
import { OidcProfile } from 'piral-oidc/src/types'
import { OidcClient } from 'piral-oidc'

interface PbaUserMenuProps {
  oidcClient: OidcClient
}

export const PbaUserMenu = ({ oidcClient }: PbaUserMenuProps) => {
  const [userProfile, setUserProfile] = useState<OidcProfile>(null)

  useEffect(() => {
    oidcClient.account().then((profile) => setUserProfile(profile))
  }, [oidcClient])

  return (
    (userProfile && (
      <UserMenu
        preferredUsername={userProfile.nickname}
        givenName={userProfile.given_name}
        familyName={userProfile.family_name}
      >
        <UserActions oidcClient={oidcClient} />
      </UserMenu>
    )) || <LoadingIndicator type={LoadingIndicatorType.CONTENT} />
  )
}
